<div #entirePage style="width:100vw; height:auto; overflow-y:scroll;">  
  <div class="main-body-narrow-padding" style="text-align: center;" #registerPanel>
    <div style="max-width: 500px; margin: 0 auto;">
      <div [hidden]="registrationComplete">
        <div class="title-container" #titleBlock>
          <div class="desktop-only-content">
            <div class="desktop-welcome">Welcome to</div>
            <img src="assets/cp-logo.png" class="desktop-logo">
            <!--<div [hidden]="!partnerJourney" class="partner-message">In partnership with
        <img src={{partnerImgSrc}}>
      </div>-->
          </div>
          <div class="title-inner-container">
            {{ registrationTitle2 }}
          </div>
        </div>
        <div class="title-offset"></div>
        <div class="invisible-card">
          <div class="side-image-overflow-preventer">
            <div class="side-image" style="display:none;"><img src="/assets/Car.png"></div>
          </div>
        </div>


        <div class="raised-card" (click)="sectionName!='createAccount' ? pickSection('createAccount') : null; changeTitle(1)" #accountPanel
             [ngStyle]="createAccountComplete ? {'pointer-events' : 'none'} : {}">
          <div class="raised-card-title">Create Account</div>
          <div [hidden]="!createAccountComplete" class="tick-circle">8</div>
          <div class="raised-card-content" [hidden]="sectionName!='createAccount'">
            <form #loginForm="ngForm">
              <div class="form-group reg">
                <div class="input-group">
                  <!--<input type="email" class="form-control" value="{{username}}" placeholder="joe.bloggs@email.com"
            readonly spellcheck="false">-->
                  <input type="email" class="form-control" value="{{username}}" placeholder="my.email@email.com"
                         spellcheck="false" (click)="$event.target.select()">
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input [type]="(showPassword === true) ? 'text' : 'password'"
                         [ngClass]="(passwordError == true && passwordModel.value!=null &&passwordModel.value!='')?'form-control form-error':'form-control'"
                         id="password" [(ngModel)]="password" name="password" #passwordModel="ngModel"
                         [passwordPolicyValidator]="passwordPolicy" spellcheck="false" placeholder="Create a password"
                         [ngStyle]="password == null || password == '' ? {'background-color' : 'white'} : {}"
                         (input)="checkPassword()">
                  <div class="password-eye">
                    <div class="eye" (click)="showHidePassword()" title="show/hide password">E</div>
                  </div>
                </div>
                <div class="password-info-container">
                  <div [hidden]="passwordError==false" class="password-info" style="// color:red;">
                    Your password must include at least 8 characters, a mixture of both uppercase and lowercase letters and a number.
                  </div>
                  <div [hidden]="passwordError==true" class="password-pass-fail password-tick">​8</div>
                  <p [hidden]="passwordError==true" class="password-info">
                    Your password must include at least 8 characters, a mixture of both uppercase and
                    lowercase letters and a number.
                  </p>
                </div>
              </div>

              <div>
                <div class="standard-button" (click)="submit();" [ngStyle]="passwordError==false ? {}
              : {'opacity' : '0.5', 'pointer-events' : 'none'}">Create Account</div>
                <div class="error-message" *ngIf="registrationError!=null" style="text-align:center">
                  <br>{{registrationError}}<br><br>
                  If you need help resetting your account, please call 0333 6000 290
                </div>
                <div class="smallprint">
                  By continuing, you accept that you have read and agree to the Creditplus
                  <a href="https://www.creditplus.finance/terms-and-conditions">Terms & Conditions</a> and
                  <a href="https://www.creditplus.finance/privacy-policy">Privacy Policy</a>.
                  © Creditplus {{currentDate.getFullYear()}}. All rights reserved.
                  Creditplus is a trading name of Jump Finance Ltd (FRN 953084)
                  registered in England (Registration Number 12665481. ICO Number ZA768331).
                </div>
              </div>
            </form>
          </div>
        </div>

        <div [hidden]="!createAccountComplete">
          <div class="standard-button" (click)="redirectToLogin();" [ngStyle]="true ? {} : {'opacity' : '0.5', 'pointer-events' : 'none'}">Continue</div>
        </div>

        <div *ngIf="display" class="raised-card" style="text-align:left;">
          <div class="contact-row"></div><div class="fixed-details">From your portal account:</div>
          <div class="contact-row"></div><div class="fixed-details">● See your finance options with personalised offers</div>
          <div class="contact-row"></div><div class="fixed-details">● Choose from our RAC approved cars, or tell us about a car you've already found</div>
        </div>

        <div class="raised-card" *ngIf="this.showPhone" id="phone" (click)="sectionName!='phone' ? pickSection('phone') : null; changeTitle(2)"
             [ngStyle]="phoneComplete ? {'pointer-events' : 'none'} : {}" #phonePanel>
          <div class="raised-card-title">Mobile Number</div>
          <div [hidden]="!phoneComplete" class="tick-circle">8</div>
          <div class="raised-card-content" [hidden]="sectionName!='phone'">
            <form #loginForm="ngForm">
              <div class="form-group reg">
                <div class="input-group">
                  <input type="text" class="form-control with-country-code" name="telephone" [(ngModel)]="telephone"
                         [pattern]="telephonePattern"
                         [ngStyle]="telephone == null || telephone == '' ? {'background-color' : 'white'} : {}"
                         #telephoneNumber="ngModel" (input)="checkPhoneInput()" placeholder="Please enter your mobile number">
                  <div class="country-code">+44 (0)</div>
                </div>
                <div *ngIf="telephoneNumber.errors && telephone.charAt(0)!='0'">
                  <div *ngIf="telephoneNumber.errors.pattern" class="error-message">
                    Mobile number is not valid. Please
                    enter a valid UK telephone number
                  </div>
                </div>
              </div>
              <div style="margin-left:0px; text-align:left;">
                <div _ngcontent-pfu-c163="" class="password-pass-fail password-tick">​8</div>

                <p style="margin-top: -28px; margin-left: 25px;">To help us verify your identity</p>

                <div _ngcontent-pfu-c163="" class="password-pass-fail password-tick">​8</div>

                <p style="margin-top: -28px; margin-left: 25px;">To get personalised finance offers</p>

                <div _ngcontent-pfu-c163="" class="password-pass-fail password-tick">​8</div>

                <p style="margin-top: -28px; margin-left: 25px;">To access our RAC- approved car search</p>
              </div>
              <div class="standard-button" (click)="validatePhoneNumber();" [ngStyle]="mobileNumberDisabled==true ?
                 {} : {'opacity' : '0.5', 'pointer-events' : 'none'}">Next</div>
            </form>
          </div>
        </div>

        <div class="raised-card" id="" [hidden]="this.showEmployment == false" [ngStyle]="employmentComplete ? {'pointer-events' : 'none'} : {}"
             (click)="sectionName!='employment' ? pickSection('employment') : null; changeTitle(2)"
             #employmentPanel>
          <div class="raised-card-title">
            Employment
          </div>
          <div [hidden]="!employmentComplete" class="tick-circle">8</div>
          <div class="raised-card-content" [hidden]="sectionName!='employment'">
            <div class="form-notice" style="display:none;">
              We need to know three years employment history. Dont worry, your employer will not be
              contacted.
            </div>

            <div *ngIf="firstEmploymentAddress==false">
              <div class="history-divider"></div>
              <div *ngFor="let employer of application.mainApplicant.employment; let i = index" class="employment-history">
                <div class="pen-icon" (click)="editEmployment(i);">F</div>
                <div class="employment-history-type" *ngIf="employer.status=='SEMP' || employer.status=='EMP'">
                  {{employer.jobTitle}}<div class="right-fade"></div>
                </div>
                <div class="employment-history-type" *ngIf="employer.status=='STD'">Student<div class="right-fade"></div></div>
                <div class="employment-history-type" *ngIf="employer.status=='RT'">Retired<div class="right-fade"></div></div>
                <div class="employment-history-type" *ngIf="employer.status=='UNEMP'">Unemployed<div class="right-fade"></div></div>
                <div class="employment-history-type" *ngIf="employer.status=='OTHER'">Other<div class="right-fade"></div></div>

                <div class="employment-history-dates">
                  {{
("0" +
                (employer.startDate.getMonth()+1)).slice(-2)
                  }}/{{employer.startDate.getFullYear()}} to
                  <span *ngIf="employer.endDate!=null">
                    {{
("0" +
                  (employer.endDate.getMonth()+1)).slice(-2)
                    }}/{{employer.endDate.getFullYear()}}
                  </span>
                  <span *ngIf="employer.endDate==null">Current</span>
                </div>
              </div>
              <div class="history-divider"></div>
            </div>

            <div>
              <div class="form-group reg">
                <label class="card-input-label">Employment type</label>
                <div class="input-group">
                  <div class="bend-these-select-boxes-to-my-will">
                    <select class="form-control registration-dropdown" [(ngModel)]="employmentStatus"
                            (change)="checkEmploymentType()">
                      <option value="" selected disabled>Please select</option>
                      <option value="EMP">Employed</option>
                      <option value="SEMP">Self-employed</option>
                      <option value="UNEMP">Unemployed</option>
                      <option value="STD">Student</option>
                      <option value="RT">Retired</option>
                      <option value="OTHER">Other</option>
                    </select>
                  </div>
                </div>
                <div class="error-message" [hidden]="employmentStatusError == false">
                  Please select an employment type
                </div>
              </div>
              <div [hidden]="employmentStatus=='STD' || employmentStatus=='RT' || employmentStatus == 'UNEMP' || employmentStatus=='OTHER'">
                <div class="form-group reg">
                  <label class="card-input-label">Job title</label>
                  <div class="input-group">
                    <input class="form-control" name="jobTitle" [(ngModel)]="jobTitle"
                           [ngStyle]="jobTitle == null || jobTitle == '' ? {'background-color' : 'white'} : {}"
                           placeholder="Please enter your job title" (input)="checkInput()">
                  </div>
                  <div class="error-message" [hidden]="jobTitleError == false">Please enter a job title</div>
                </div>
                <div class="multiple-inputs-inline" style="margin-top:-10px;">
                  <div class="inline-block-half">
                    <div class="form-group reg">
                      <label class="card-input-label">Start date</label>
                      <div class="input-group">
                        <input type="month" class="form-control" name="startDate" [(ngModel)]="startDate"
                               [ngStyle]="startDate == null || startDate == '' ? {'background-color' : 'white', 'color' : 'white'} : {'color' : '#e8f0fe'}"
                               style="padding-left: calc(100% - 60px);" (input)="checkInput()">
                        <div class="date-formatter" *ngIf="startDate!=null && startDate!=''">
                          {{startDate.split("-")[1]}}/{{startDate.split("-")[0].substring(2)}}
                        </div>
                        <div class="date-formatter" [hidden]="startDate!=null && startDate!=''" style="color: #777777;">
                          MM/YY
                        </div>
                      </div>
                      <div class="error-message" [hidden]="startDateError == false">Please select a start date</div>
                      <div class="error-message" [hidden]="startDateFutureError == false">
                        Start date cannot be in the
                        future
                      </div>
                      <div class="error-message" [hidden]="startDateDuplicateError == false">Start date already exists in employment history</div>
                    </div>
                  </div>
                  <div class="inline-block-half" [hidden]="firstEmploymentAddress==false">
                    <div class="form-group reg">
                      <label class="card-input-label">
                        <span class="monthly-span" style="margin-right:3px;">
                          Monthly
                          income
                        </span><span class="monthly-span">(after tax)</span>
                      </label>
                      <div class="input-group">
                        <input type="text" class="form-control" name="monthlyIncome" [(ngModel)]="monthlyIncome"
                               [ngStyle]="monthlyIncome == null || monthlyIncome == '' ? {'background-color' : 'white'} : {}"
                               placeholder="" style="padding-left: 20px;" (input)="checkIncomeInput()">
                        <div class="monthly-pound-sign">£</div>
                      </div>
                      <div class="error-message" [hidden]="monthlyIncomeError == false">
                        Please enter a monthly income
                      </div>
                    </div>
                  </div>
                </div>

                <div [hidden]="employmentStatus!='EMP'">
                  <div class="form-group reg">
                    <label class="card-input-label">Company industry</label>
                    <div class="input-group">
                      <div class="bend-these-select-boxes-to-my-will">
                        <select class="form-control registration-dropdown" [(ngModel)]="employmentIndustry">
                          <option value="" selected disabled>Please select</option>
                          <option value="1">Accountancy</option>
                          <option value="2">Admin, Secretarial and PA</option>
                          <option value="3">Apprenticeships</option>
                          <option value="4">Armed Forces</option>
                          <option value="5">Banking and Financial Services</option>
                          <option value="6">Charity</option>
                          <option value="7">Construction and Property</option>
                          <option value="8">Customer Service</option>
                          <option value="9">Education/Teaching</option>
                          <option value="10">Energy</option>
                          <option value="11">Engineering</option>
                          <option value="12">Entertainment</option>
                          <option value="13">Estate Agency</option>
                          <option value="14">Healthcare and Medicine</option>
                          <option value="15">Home Maker</option>
                          <option value="16">Hospitality and Catering</option>
                          <option value="17">Human Resources</option>
                          <option value="18">Insurance</option>
                          <option value="19">IT and Telecoms</option>
                          <option value="20">Legal</option>
                          <option value="21">Leisure and Tourism</option>
                          <option value="22">Management</option>
                          <option value="23">Manufacturing</option>
                          <option value="24">Marketing and PR</option>
                          <option value="25">Media, Digital and Creative</option>
                          <option value="26">Motor Trade</option>
                          <option value="27">Private Hire, Taxis and Chauffeur</option>
                          <option value="28">Public Sector</option>
                          <option value="29">Recruitment</option>
                          <option value="30">Retail</option>
                          <option value="31">Retired</option>
                          <option value="32">Sales</option>
                          <option value="33">Security</option>
                          <option value="34">Social Care</option>
                          <option value="35">Student</option>
                          <option value="36">Sport</option>
                          <option value="37">Transport and Logisitcs</option>
                          <option value="38">Unknown</option>
                          <option value="39">Other</option>
                        </select>
                      </div>
                    </div>
                    <div class="error-message" [hidden]="employmentIndustryError == false">Please select an industry</div>
                  </div>
                  <div class="form-group reg">
                    <label class="card-input-label">Company name</label>

                    <div class="input-group">
                      <input class="form-control" name="companyName" [(ngModel)]="companyName"
                             [ngStyle]="companyName == null || companyName == '' ? {'background-color' : 'white'} : {}"
                             placeholder="Please enter your company name" (input)="checkInput()">
                    </div>
                    <div class="error-message" [hidden]="companyNameError == false">Please enter a company name</div>
                  </div>
                </div>

                <div [hidden]="employmentStatus!='SEMP'">
                  <div class="form-group reg">
                    <label class="card-input-label">Address line 1</label>
                    <div class="input-group">
                      <input class="form-control" name="jobTitle" [(ngModel)]="addressLine1"
                             [ngStyle]="addressLine1 == null || addressLine1 == '' ? {'background-color' : 'white'} : {}"
                             placeholder="Please enter the first address line">
                    </div>
                    <div class="error-message" [hidden]="jobTitleError == false">Please enter address line 1</div>
                  </div>
                  <div class="form-group reg">
                    <label class="card-input-label">Address line 2</label>
                    <div class="input-group">
                      <input class="form-control" name="jobTitle" [(ngModel)]="addressLine2"
                             [ngStyle]="addressLine2 == null || addressLine2 == '' ? {'background-color' : 'white'} : {}"
                             placeholder="Please enter the second address line">
                    </div>
                  </div>
                  <div class="form-group reg">
                    <label class="card-input-label">Town</label>
                    <div class="input-group">
                      <input class="form-control" name="jobTitle" [(ngModel)]="town"
                             [ngStyle]="town == null || town == '' ? {'background-color' : 'white'} : {}"
                             placeholder="Please enter the address town">
                    </div>
                    <div class="error-message" [hidden]="jobTitleError == false">Please enter town</div>
                  </div>
                  <div class="form-group reg">
                    <label class="card-input-label">Postcode</label>
                    <div class="input-group">
                      <input class="form-control" name="jobTitle" [(ngModel)]="postCode"
                             [ngStyle]="postCode == null || postCode == '' ? {'background-color' : 'white'} : {}"
                             placeholder="Please enter the address postcode">
                    </div>
                    <div class="error-message" [hidden]="jobTitleError == false">Please enter postcode</div>
                  </div>
                </div>

              </div>
              <div [hidden]="!(employmentStatus=='STD'||employmentStatus=='RT'
            ||employmentStatus=='UNEMP'||employmentStatus=='OTHER')">
                <div class="form-group reg">
                  <label class="card-input-label">Start date</label>
                  <div class="input-group" style="max-width:187px;">
                    <input type="month" class="form-control" name="startDate" [(ngModel)]="startDate"
                           [ngStyle]="startDate == null || startDate == '' ? {'background-color' : 'white', 'color' : 'white'} : {'color' : '#e8f0fe'}"
                           style="padding-left: calc(100% - 60px);" (input)="ValidateDateHistory()" (onchange)="ValidateDateHistory()" (onkeydown)="ValidateDateHistory()">
                    <div class="date-formatter" *ngIf="startDate!=null && startDate!=''">
                      {{startDate.split("-")[1]}}/{{startDate.split("-")[0].substring(2)}}
                    </div>
                    <div class="date-formatter" [hidden]="startDate!=null && startDate!=''" style="color: #777777;">
                      MM/YY
                    </div>
                  </div>
                  <div class="error-message" [hidden]="startDateError == false">Please select a start date</div>
                  <div class="error-message" [hidden]="startDateFutureError == false">
                    Start date cannot be in the future
                  </div>
                  <div class="error-message" [hidden]="startDateDuplicateError == false">Start date already exists in employment history</div>
                </div>
              </div>
              <!--<div class="add-another"><span class="plus-icon">+</span>Add another</div>-->

              <div class="standard-button" (click)="validateEmployment(); submitVehicleNeeds();" [ngStyle]="imputValue==true ?
                 {} : {'opacity' : '0.5', 'pointer-events' : 'none'}">
                <span *ngIf="updatingEmployment==false">Complete</span>
                <span *ngIf="updatingEmployment==true">Update</span>
              </div>
              <div class="error-message" *ngIf="historyRequiredYears > 0 || historyRequiredMonths > 0">Three years of employment history is required.</div>
              <div class="error-message" *ngIf="historyRequiredYears > 0 || historyRequiredMonths > 0">
                Please provide another
                <span *ngIf="historyRequiredYears > 0">
                  {{historyRequiredYears}}
                  year<span *ngIf="historyRequiredYears > 1">s</span>
                </span>
                <span *ngIf="historyRequiredYears > 0 && historyRequiredMonths > 0"> and </span>
                <span *ngIf="historyRequiredMonths > 0">
                  {{historyRequiredMonths}}
                  month<span *ngIf="historyRequiredMonths > 1">s</span>
                </span> of employment history
              </div>
            </div>
          </div>
        </div>
      </div>

    <div style="display:none;" class="raised-card" (click)="sectionName!='vehicle-needs' ? pickSection('vehicle-needs') : null; changeTitle(3)" 
    [hidden]="showVehicleNeeds == false"  [ngStyle]="vehicleNeedsComplete ? {'pointer-events' : 'none'} : {}" #vehicleNeedsPanel>
      <div class="raised-card-title">Vehicle</div>
      <div class="raised-card-content"  [hidden]="sectionName!='vehicle-needs'">
      <div class="card-input-label">Either finance a car you've found at a dealership or choose a quality-checked Creditplus car and get it delivered to your door.<br><br><br>
        Have you found the exact vehicle you'd like to buy?</div>
      <div class="yes-no-block">
        <div class="yes-no-button" (click)="vehicleFound(true)" [ngStyle]="vehicleAlreadyFound==true ?
           {'border' : '1px solid #c50084', 'color' : '#c50084'} : {}">Yes
          <div class="yes-no-outer-circle" [ngStyle]="vehicleAlreadyFound==true ? {'border' : '1px solid #c50084'} : {}">
            <div class="yes-no-inner-circle" [ngStyle]="vehicleAlreadyFound==true ? {'background-color' : '#c50084'} : {}"></div>
          </div>
        </div>
        <div class="yes-no-button" (click)="vehicleFound(false)" [ngStyle]="vehicleAlreadyFound==false ?
        {'border' : '1px solid #c50084', 'color' : '#c50084'} : {}">No
          <div class="yes-no-outer-circle" [ngStyle]="vehicleAlreadyFound==false ? {'border' : '1px solid #c50084'} : {}">
            <div class="yes-no-inner-circle" [ngStyle]="vehicleAlreadyFound==false ? {'background-color' : '#c50084'} : {}"></div>
          </div>
        </div>
      </div>
      <div class="standard-button" (click)="submitVehicleNeeds();" [ngStyle]="vehicleAlreadyFound==null ?
          {'opacity' : '0.5', 'pointer-events' : 'none'} : {}">Complete</div>
      </div>      
    </div>
    <div [hidden]="!registrationComplete">
      <registration-complete></registration-complete>
    </div>
    </div>
  </div>
</div>
