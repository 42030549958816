<div class="desktop-only">
  <div style="position:absolute; top:0; bottom:0; left:0; right:0; width:100vw; height:100vh; z-index:100; background-color:white;">
<div class="main-body">
  <div style="width:100%; text-align:center">
    <div class="desktop-welcome">Welcome to</div>
    <img src="assets/cp-logo.png" class="desktop-logo">
</div>
<div class="medium-title">Oops! Trying to access your Customer Portal?</div>
<div class="inline-content">
  
  <div>
    <div class="sub-title">
      How to access your Portal account:
    </div>
    <div class="completion-card-text" style="margin-bottom:40px; max-width: 435px;">
      Currently the Customer Portal is only accessible via mobile.<br><br>
      The easiest way to open this page on your mobile is to send yourself a link, follow the direction below.
    </div>
<div class="raised-card completion-card">
  <div class="or-seperator">Get Link</div>
  <div class="completion-card-text" style="margin-top:-15px">To be able to access your portal copy or share the link between yourself, then open it on mobile.</div>
  <div class="inline-card-content">
  <input class="portal-link" value="https://portal.creditplus.finance/login" id="portal-link" disabled>
  <div class="link-group">
  <div class="copy-portal-link" [hidden]="emailSent==true" *ngIf="contactId!=null && contactId!='' && contactEmail!=null" (click)="sendLinkEmail()">
    <span class="cp-icon">e</span>Email link</div>
    <div class="copied-portal-link" [hidden]="emailSent==false"><span class="cp-icon">u</span>Email sent</div>
  <div class="copy-portal-link" [hidden]="textCopied==true" (click)="copyToClipboard()">
    <span class="cp-icon">c</span>Copy link
    
  </div>
  <div class="copied-portal-link" [hidden]="textCopied==false"><span class="cp-icon">u</span>Link copied</div>
  </div>
  </div>
 
</div>
</div>
<div class="phone-image-container"><img src="/assets/Registration-complete.png"></div>
</div>
<div style="width:100%; height:120px;"></div>
</div>
</div>
<div class="landscape-mobile-only">
    <div style="text-align:center; margin-top:40px;">Your mobile device is too wide to display the portal in landscape mode.<br/> <br/>Please rotate your phone.</div>
  </div>
</div>

