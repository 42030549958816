<div class="mobile-only">
<div class="fixed-header">
  <div class="fixed-page-title-vehicle-serarch fixed-page-title-vehicle-serarch-backbtn">
    <a routerLink="/applications/{{application.id}}/settings">
      <div class="raised-back-button">
        <div class="raised-back-button-icon">L</div>
     </div>
    </a>
  </div>
</div>
<div class=fixed-header-fade></div>
<div class="fixed-header-offset"></div>
<div class="main-body-narrow-padding">
<div class="medium-title">
  Help Centre
</div>

<div class="raised-card">
<div class="menu-div">
<div class="menu-option" routerLink="/applications/{{application.id}}/help/faqs"> Frequently Asked Questions </div>
<div class="menu-arrow" routerLink="/applications/{{application.id}}/help/faqs">M</div>
</div>
<div class="horizontal-divider" ></div>
<div class="menu-div" >
<div class="menu-option" onclick="window.open('https://www.creditplus.finance/car-finance-glossary','_blank')"> Glossary </div>
<div class="menu-arrow" onclick="window.open('https://www.creditplus.finance/car-finance-glossary','_blank')">M</div>
</div>
<div class="horizontal-divider" ></div>
<div class="menu-div">
<div class="menu-option" onclick="window.open('https://www.creditplus.finance/blog','_blank')"> Blog </div>
<div class="menu-arrow" onclick="window.open('https://www.creditplus.finance/blog','_blank')">M</div>
</div>
<div class="horizontal-divider" ></div>
<div class="menu-div" >
<div class="menu-option" routerLink="/applications/{{application.id}}/help/contact"> Contact </div>
<div class="menu-arrow" routerLink="/applications/{{application.id}}/help/contact">M</div>
</div>
</div>
</div>
<navbar [currentSection]="'account'"></navbar>
</div>
