import { Component, OnInit, Input } from '@angular/core';
import { Sms, UserService } from '../../../core/services/user/user.service';

@Component({
  selector: 'registration-complete',
  templateUrl: './registration-complete.component.html',
  styleUrls: ['./registration-complete.component.scss']
})
export class RegistrationCompleteComponent implements OnInit {
  @Input() mobileNumber: string;
  textCopied:boolean = false;

  constructor(private userService: UserService) { }

  ngOnInit() {
  }

  copyToClipboard(){
    this.textCopied = true;
    var text:string = "https://portal.creditplus.finance/login"
    //console.log("text click as " + text);
    navigator.clipboard.writeText(text);
    setTimeout(()=>{
      this.textCopied=false;
    },2000);
  
  }

  async sendSms() {

    let sms: Sms = {
      PhoneNumber: this.mobileNumber,
      SenderName: "Creditplus",
      MessageBody: "GOTO PORTAL https://portal.creditplus.finance/login"
    }

   await this.userService.sendSms(sms).toPromise();
 }
}
