<div class="main-body">
<div class="medium-title">Great news, your application has been sent!</div>
<div class="inline-content">
<div class="raised-card completion-card">
  <div class="raised-card-title slightly-bigger">We just sent a link to +44********###</div>
  <div class="completion-card-text">This link will give you access to our Portal which will enable you to find your next car,
    get loan offers and track your application easily, all in one place.<br/><br/>
    Currently the Customer Portal is only accessible via mobile, so please follow the link we sent you.
  </div>
  <div class="completion-card-prompt">Didn't get a text?
    <a class="completion-card-action" (click)="sendSms()">>Resend SMS</a>
  </div>
  <div class="or-seperator">OR</div>
  <div class="completion-card-text">To be able to access your portal copy or share the link between yourself, then open it on mobile.</div>
  <div class="inline-card-content">
  <input class="portal-link" value="https://portal.creditplus.finance/login">
  <div class="link-group">
  <i class="fas fa-link"></i>
  <div class="copy-portal-link" [ngStyle]="textCopied==true ? {'color' : 'RGBA(255,255,255,0)'} : {}" (click)="copyToClipboard()">
    Copy Link
    <div class="copied-portal-link" [hidden]="textCopied==false">Copied</div>
  </div>
  </div>
  </div>
  <div class="below-card">
  <div class="completion-card-prompt">Already on mobile?
    <a class="completion-card-action" routerLink="/login">Login</a>
  </div>
  </div>
</div>
<div class="phone-image-container"><img src="/assets/Registration-complete.png"></div>
</div>
<div style="width:100%; height:120px;"></div>
</div>
